<template>
  <div class="c-channel-hero__image-container">
    <slot name="mainImage">
      <HeroImage :alt="alt"
      :imgref="imgref"
      />
    </slot>
    <div class="c-channel-hero__flex-container">
      <div class="c-channel-hero__title">{{ title }}<OmMark v-if="isOm" theme="white" /></div>
      <template v-if="!shareSomething">
      <a v-if="showShareLink" href="#share-something" class="c-channel-hero__href" @click.prevent="shareSomething = true">
        <div class="c-channel-hero__subtitle">
          <span class="c-channel-hero__plus">+</span><span class="c-channel-hero__subtitle_text">share something</span>
        </div>
      </a>
      <a v-if="subtitleURL" :href="subtitleURL" class="c-channel-hero__href">
        <div class="c-channel-hero__subtitle">
          <span class="c-channel-hero__plus"><span v-if="join">+</span><span v-else>-</span></span><span class="c-channel-hero__subtitle_text">{{ subtitle }}</span>
        </div>
      </a>
      </template>
      <template v-else>
      <ul class="c-channel-hero-share-menu">
        <li v-for="(link, index) in shareSomethingLinks" :key="index">
          <a :href="link.href" class="c-channel-hero__href">
            <div class="c-channel-hero__subtitle">
              <span class="c-channel-hero__plus">+</span><span class="c-channel-hero__subtitle_text">{{ link.label }}</span>
            </div>
          </a>
        </li>
        <li>
          <a href="#close-share-something" class="c-channel-hero__href" @click.prevent="shareSomething = false">
            <div class="c-channel-hero__subtitle">
              <span class="c-channel-hero__plus">x</span>
            </div>
          </a>
        </li>
      </ul>
      </template>
    </div>
  </div>
</template>

<script>
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage.vue'
import OmMark from '@dharmachakra/tbco-shared-styles/src/components/atoms/OmMark.vue'

export default {
  components: {
    HeroImage,
    OmMark
  },
  props: {
    imgref: {
      type: String
    },
    alt: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    subtitleURL: {
      type: String
    },
    showShareLink: {
      type: Boolean,
      default: false
    },
    shareSomethingLinks: {
      type: Array,
      default() {
        return []
      }
    },
    join: {
      type: Boolean,
      default: true
    },
    isOm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareSomething: false
    }
  }
}
</script>

<style lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';

.t-project, .t-group {
    .c-channel-hero__title {
        background-color: rgba(228, 97, 29, 0.85);
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 0.81pt;
        font-size: 26px;
        font-weight: 700;
        padding-top: 13px;
        padding-bottom: 13px;
        flex-grow: 1;
        text-indent: 27px;
        font-style: normal;
        text-transform: lowercase;
        height: 4rem;

        @media screen and (max-width: $tablet-max-size) {
            font-size: 24px;
            letter-spacing: 0.75px;
            width: 100%;
        }

        @media screen and (max-width: $mobile-max-size) {
            font-size: 16px;
            letter-spacing: 0.5px;
            padding-top: 20px;
            text-indent: 9px;
        }
    }

    .c-channel-hero__href {
        text-decoration: none;
        height: 4rem;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.85);
    }

    .c-channel-hero__subtitle {
        color: #e4611d;
        text-decoration: none;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 0.63pt;
        font-size: 20px;
        font-weight: 600;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-right: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        .c-channel-hero__subtitle_text {
          text-decoration: underline;
          flex-grow: 0;
          margin-left: 6px;
        }

        @media screen and (max-width: $mobile-max-size) {
            font-size: 16px;
            letter-spacing: 0.5px;
        }
    }
    @media screen and (width > 1000px) {
        .c-channel-hero__subtitle {
            padding-right: 40px;
        }
    }
    .c-channel-hero__plus{
        color: #e4611d;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 0.81pt;
        font-size: 26px;
        font-weight: 700;
        padding: 0;
        position: relative;
        top: 3px;
        flex-grow: 0;
        margin-left: 27px;

        @media screen and (max-width: $mobile-max-size) {
            font-size: 16px;
            letter-spacing: 0.5px;
            margin-left: 9px;
        }
    }

    .c-channel-hero__image-container {
        position: relative;
    }

    .c-channel-hero__flex-container {
        display: flex;
        flex-wrap: wrap;
        bottom: 0px;
        width: 100%;
        position: relative;
        margin-top: -4rem;
    }

    .c-channel-hero__image{
        width: 100%;
        object-fit: cover;
        height: 438px;
    }
}
.t-group {
    .c-channel-hero__title {
        background-color: rgba(89, 128, 0, 0.85);
    }
    .c-channel-hero__subtitle, .c-channel-hero__plus {
        color: $colour-group-green;
    }
}

.c-channel-hero-share-menu {
  display: flex;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;

    .c-channel-hero__href {
      height: 2.5rem;
    }
  }
}
</style>
